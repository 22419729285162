import React from 'react';
import css from './YouMightAlsoLikeCard.module.css';
import { IconReviewStar, NamedLink } from '../../../../components';

import item from './item.png'
import Bottle from '../../../../components/review/Bottle';
// import Bottle from '../../../../components/review/Bottle';

function YouMightAlsoLikeCard() {
  return (
    <NamedLink name="Winery">
      <div className={css.youMightAlsoLikeCard}>
        <div className={css.youMightAlsoLikeCardHeader}>
          <img src={item} alt="" />
        </div>
        <div className={css.youMightAlsoLikeCardBody}>
          <h2>Southold Farm + Cellar</h2>
          <h5>Hye, Texas</h5>
        </div>
        <div className={css.youMightAlsoLikeCardFooter}>
          <p>Sommly Score:</p>
          <div className={css.youMightAlsoLikeCardBottleReview}>
             {[1, 2, 3, 4, 5].map((_, index) => (
                <Bottle />
              ))}
          </div>
        </div>
      </div>
    </NamedLink>
  )
}

export default YouMightAlsoLikeCard
