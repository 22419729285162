import React from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { ListingCard, PaginationLinks } from '../../../components';
import YouMightAlsoLikeCard from './YouMightAlsoLikeCard/YouMightAlsoLikeCard'

import css from './SearchResultsPanel.module.css';
import SearchMap from '../SearchMap/SearchMap';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    // pagination,
    search,
    setActiveListing,
    isMapVariant,
    activeListingId,
    bounds,
    onMapMoveEnd,
    mapListings,
    origin,
  } = props;
console.log('search', search);

  const pagination = {
    page: 1,
    perPage: 6,
    totalItems: 24,
    totalPages: 4,
  }
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  return (
    <div className={classes}>
      <div className={css.mapWrapper}>
          <SearchMap
            rootClassName={css.mapRoot}
            reusableContainerClassName={css.map}
            activeListingId={activeListingId}
            bounds={bounds}
            center={origin}
            // isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            location={location}
            listings={mapListings}
            onMapMoveEnd={onMapMoveEnd}
            // onCloseAsModal={() => {
            //   onManageDisableScrolling('SearchPage.map', false);
            // }}
            // messages={intl.messages}
          />
      </div>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        {/* {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(isMapVariant)}
            setActiveListing={setActiveListing}
          />
        ))} */}
        {/* <div> */}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(l => (
            <YouMightAlsoLikeCard />
          ))}
        {/* </div> */}
        {props.children}
      </div>
      <div className={css.paginationWrapper}>
        {paginationLinks}
      </div>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;
